/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Map from "@material-ui/icons/Map";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <div className={classes.blockDetails}>
                <div className={classes.blockHeader}><b>Address</b></div>
                <div className={classes.blockdetails}><Map className={classes.icon} /> 248 Bagmari Road Kolkata -700054</div>
              </div>
              {/* <div className={classes.block}>
              Address --
              248 Bagmari Road
              Kolkata -700054
              </div> */}
              
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <div className={classes.blockDetails}>
                  <div className={classes.blockHeader}><b>Email</b></div>
                  <div className={classes.blockdetails}>bbsdc1920@gmail.com</div>
              </div>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <div className={classes.blockDetails}>
                  <div className={classes.blockHeader}><b>Phone</b></div>
                  <div className={classes.blockdetails}>+91 8100331619</div>
              </div>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          {/* &copy; {1900 + new Date().getYear()} , made with{" "}
          <Map className={classes.icon} /> by{" "} */}
          © Copyright 2020 Bagmari Club - All Rights Reserved
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
