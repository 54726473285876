import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

const useStyles = makeStyles(styles);

export default function AboutUsSection() {
	const classes = useStyles();
  // const imageClasses = classNames(
  //   classes.imgRaised,
  //   classes.imgRoundedCircle,
  //   classes.imgFluid
  // );
	return (
    <div className={classes.section}>
      <h2 className={classes.title}>About Us</h2>
			<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
			<h2 className={classes.title}>BRIHATTARA BAGMARI SARBAJANIN DURGOTSAB
                                      CELEBRATING 103 YEARS ORGANIZED BY BAGMARI CLUB (UPENDRA SMRITI MANDIR)</h2>
              <h4 className={classes.title}>
              BRIHATTARA BAGMARI SARBAJANIN DURGOTSAB COMMITTEE ( BAGMARI CLUB ), a sports {"&"} cultural club of NORTH-EAST KOLKATA, having its own playground and all the other facility. The organization was established in the year 1920. "BAGMARI CLUB" has been organizing Durga Puja/Sarodotsav during the last 102 years and especially from 2000 in a befitting and gorgeous manner at BETWEEN MANICKTALA AND KANKURGACHI of Kolkata which is well known for its aristocracy where a large number of cosmopolitan people reside in complete peace and harmony irrespective of their faith {"&"} belief toward God Durga from ancient time onwards
              </h4>
              <br />

            </GridItem>
			</GridContainer>
		</div>
	);
}