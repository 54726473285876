import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

// @material-ui/icons

// core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";

const imageFolderPath = `${process.env.PUBLIC_URL}/img/current`;
const totalImage = 112;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    // width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '1000px',
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *     cols: 2,
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
export default function GridSection() {
  const matches = useMediaQuery('(max-width:768px)');
  const classes = useStyles();
  let imageNames = [];
  for (let index = 0; index < totalImage; index++) {
    const imagePath = `${imageFolderPath}/${index}.jpg`;
    imageNames.push(imagePath);
  }

  return (
    <div className={classes.root} id="gallery">
      <GridList cellHeight={500} className={classes.gridList} cols={matches ? 1 : 2}>
        {imageNames.map((tile) => (
          <GridListTile key={tile} cols={tile.cols || 1}>
            <img src={tile} alt={tile} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
